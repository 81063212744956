import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';

/*
  Generated class for the AuthInterceptorProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class AuthInterceptorProvider implements HttpInterceptor {

  public settings: Settings;
  constructor(public appSettings:AppSettings) {
    console.log('Hello AuthInterceptorProvider Provider');
    this.settings = this.appSettings.settings;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;


    authReq = authReq.clone({
      url: this.settings.server.protocol + '://' + this.settings.server.host + ':' + this.settings.server.port + '/' + authReq.url,
    });

      return next.handle(authReq)
        .pipe(
          map((event: HttpEvent<any>) => {
              if (event instanceof HttpResponse) {
                  console.log('event--->>>', event);
              }
              return event;
          }));

  }

}
