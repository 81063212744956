import { Injectable } from '@angular/core';
import { Settings } from './app.settings.model';

@Injectable()
export class AppSettings {
    public settings = new Settings(
        'IVE',
        'IVE',
        {
            navbarIsFixed: true,
            footerIsFixed: true,
            sidebarIsFixed: true,
            skin:'combined',
        },
        {
            protocol: 'https',
            host: 'app.energysequence.com',
            port: 443,
            calculatorEndpoint: 'v2/ive/',
            loginEndpoint: 'v2/pvauth/',
            tosURL: 'terminos-y-condiciones/'
        }, {
            id: '1',
            name: 'Bettergy',
            email: 'info@bettergy.es',
            web: 'https://www.bettergy.es',
            telephone: '952 02 57 89',
            backgroundPhoto: '/assets/img/app/cargadores.jpg',
            allow_login: 1,
        }
    )
}