import { Injectable } from '@angular/core';
import { Response } from '../../interfaces/response.model';
import { Result } from '../../interfaces/result.model';

@Injectable()
export class DashboardState {
    public step = 0;

    public result:Result;

    public assessorId = '';

    public tipo_usuario:Response[] = [
        {
            key: 'tipo_usuario',
            value: 'EMPRESA',
            message: 'Empresa',
            image: 'https://cdn2.iconfinder.com/data/icons/buildings-66/92/32-512.png',
            icon: 'custom-answer11'
        },{
            key: 'tipo_usuario',
            value: 'HOGAR',
            message: 'Domicilio',
            image: 'http://aux.iconspalace.com/uploads/1788709997106428462.png',
            icon: 'custom-answer11'
        }
    ];

    public tiempo_carga:Response[] = [
        {
            key: 'tiempo_carga',
            value: '1_HORA',
            message: '1 hora',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tiempo_carga',
            value: '2_HORAS',
            message: '2 horas',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tiempo_carga',
            value: '4_HORAS',
            message: '4 horas',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tiempo_carga',
            value: '10_HORAS',
            message: '10 horas',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        }
    ];

    public tipo_sumin:Response[] = [
        {
            key: 'tipo_sumin',
            value: 'MONOFASICO',
            message: 'Monofásico (230V)',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_sumin',
            value: 'TRIFASICO',
            message: 'Trifásico (400V)',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        }
    ];

    public tipo_tarifa:Response[] = [
        {
            key: 'tipo_tarifa',
            value: '2.0A',
            message: '2.0A',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '2.0DHA',
            message: '2.0DHA',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '2.0DHS',
            message: '2.0DHS',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '2.1A',
            message: '2.1A',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '2.1DHA',
            message: '2.1DHA',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '2.1DHS',
            message: '2.1DHS',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '3.0A',
            message: '3.0A',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '3.1A',
            message: '3.1A',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_tarifa',
            value: '6.1A',
            message: '6.1A',
            image: 'assets/img/responses/answer11.svg',
            icon: 'custom-answer11'
        }
    ];

    public tipo_lugar:Response[] = [
        {
            key: 'tipo_lugar',
            value: 'INTERIOR',
            message: 'Interior',
            image: 'https://image.shutterstock.com/image-illustration/3d-illustration-garage-interior-260nw-377332477.jpg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_lugar',
            value: 'EXTERIOR',
            message: 'Exterior',
            image: 'https://upload.wikimedia.org/wikipedia/commons/5/50/Calle_Ciudad_de_Mula%2C_Valencia_01.JPG',
            icon: 'custom-answer11'
        },
    ];

    public tipo_montaje:Response[] = [
        {
            key: 'tipo_lugar',
            value: 'EN_PARED',
            message: 'En pared',
            image: 'https://sebringdesignbuild.com/wp-content/uploads/2016/10/garage-interior-design-ideas-2_Sebring-Services.jpg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_lugar',
            value: 'EN_SUELO',
            message: 'En suelo',
            image: 'https://image.shutterstock.com/image-illustration/3d-illustration-garage-interior-260nw-377332477.jpg',
            icon: 'custom-answer11'
        }
    ];

    public distancia:Response[] = [
        {
            key: 'tipo_lugar',
            value: 'A_MENOS_DE_10_METROS',
            message: 'Menos de 10 metros',
            image: 'https://sebringdesignbuild.com/wp-content/uploads/2016/10/garage-interior-design-ideas-2_Sebring-Services.jpg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_lugar',
            value: 'ENTRE_10_Y_30_METROS',
            message: 'Entre 10 y 30 metros',
            image: 'https://image.shutterstock.com/image-illustration/3d-illustration-garage-interior-260nw-377332477.jpg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_lugar',
            value: 'ENTRE_30_Y_50_METROS',
            message: 'Entre 30 y 50 metros',
            image: 'https://es.habcdn.com/photos/project/big/vista-1-1537958.jpg',
            icon: 'custom-answer11'
        },{
            key: 'tipo_lugar',
            value: 'MAS_DE_50_METROS',
            message: 'Más de 50 metros',
            image: 'https://upload.wikimedia.org/wikipedia/commons/5/50/Calle_Ciudad_de_Mula%2C_Valencia_01.JPG',
            icon: 'custom-answer11'
        }
    ];

    public tipo_vehiculo:Response[] = [
        {
            key: 'tipo_vehiculo',
            value: 'FURGON',
            message: 'Furgón',
            image: 'https://hyundai.com.py/static/img/section_transport/trucks/furgon/main.png',
            icon: 'custom-answer11'
        },{
            key: 'tipo_vehiculo',
            value: 'FURGONETA',
            message: 'Furgoneta',
            image: 'https://carflex.es/portals/0/imagenes/FurgonetaC1.png',
            icon: 'custom-answer11'
        },{
            key: 'tipo_vehiculo',
            value: 'TURISMO',
            message: 'Turismo',
            image: 'https://bipi-pro.s3.eu-west-1.amazonaws.com/specificvehicleplans/5c8b6361c99757002275d30b/vehicle-images/5c9a5bef7d638500221d974d_2019_03_26_18_05_51.png',
            icon: 'custom-answer11'
        },{
            key: 'tipo_vehiculo',
            value: 'TURISMO_DE_ALTA_GAMA',
            message: 'Turismo de alta gama',
            image: 'https://es.sx-magazine-wp.sixt.io/magazine/wp-content/uploads/sites/7/2019/03/tesla-s-4d-weiss-2016-1024x585.png',
            icon: 'custom-answer11'
        },
    ];

    public tipo_vehiculo_domicilio:Response[] = [
        {
            key: 'tipo_vehiculo',
            value: 'FURGONETA',
            message: 'Furgoneta',
            image: 'https://carflex.es/portals/0/imagenes/FurgonetaC1.png',
            icon: 'custom-answer11'
        },{
            key: 'tipo_vehiculo',
            value: 'TURISMO',
            message: 'Turismo',
            image: 'https://bipi-pro.s3.eu-west-1.amazonaws.com/specificvehicleplans/5c8b6361c99757002275d30b/vehicle-images/5c9a5bef7d638500221d974d_2019_03_26_18_05_51.png',
            icon: 'custom-answer11'
        },{
            key: 'tipo_vehiculo',
            value: 'TURISMO_DE_ALTA_GAMA',
            message: 'Turismo de alta gama',
            image: 'https://es.sx-magazine-wp.sixt.io/magazine/wp-content/uploads/sites/7/2019/03/tesla-s-4d-weiss-2016-1024x585.png',
            icon: 'custom-answer11'
        },{
            key: 'tipo_vehiculo',
            value: 'MOTO_O_BICICLETA',
            message: 'Moto o bicicleta',
            image: 'https://corporate.silence.eco/wp-content/uploads/2016/05/s02-lateral.png',
            icon: 'custom-answer11'
        },
    ];



}