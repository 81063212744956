export class Settings {
    constructor(public name: string,
                public title: string,
                public theme: {
                    navbarIsFixed: boolean,
                    footerIsFixed: boolean,
                    sidebarIsFixed: boolean,
                    skin:string,
                },
                public server: {
                    protocol: string,
                    host: string,
                    port: number,
                    calculatorEndpoint: string,
                    loginEndpoint: string,
                    tosURL: string,
                },
                public client: {
                    id: string,
                    name: string,
                    email: string,
                    web: string,
                    telephone: string,
                    backgroundPhoto: string,
                    allow_login: number,
                }
                ) {

    }
}