import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators} from '@angular/forms';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {

    @ViewChild('footerModal') private footerModal;
    public modalRef: NgbModalRef;

  public settings: Settings;
  public mailto: string;

   public footerForm:FormGroup;

    public name:AbstractControl;
    public city:AbstractControl;
    public phone:AbstractControl;
    public email:AbstractControl;
    public description:AbstractControl;
    public tos:AbstractControl;


  constructor(public appSettings:AppSettings, public modalService: NgbModal, fb:FormBuilder, public toastrService: ToastrService) {
      this.settings = this.appSettings.settings;
      this.mailto = 'mailto:' + this.settings.client.email;

      this.footerForm = fb.group({
          name: ['', Validators.compose([Validators.required])],
          city: ['', Validators.compose([Validators.required])],
          phone: ['', Validators.compose([Validators.minLength(9)])],
          email: ['', Validators.compose([Validators.required, emailValidator])],
          description: ['', Validators.compose([Validators.required])],
          tos: [true, Validators.requiredTrue]
      });

      this.name = this.footerForm.controls['name'];
      this.city = this.footerForm.controls['city'];
      this.phone = this.footerForm.controls['phone'];
      this.email = this.footerForm.controls['email'];
      this.description = this.footerForm.controls['description'];
      this.tos = this.footerForm.controls['tos'];

  }

  ngOnInit() {
  }

  contact() {
      this.modalRef = this.modalService.open(this.footerModal);

      this.modalRef.result.then((result) => {

      }, (reason) => {

      });
  }


    public onSubmit(values:Object):void {
        if (this.footerForm.valid) {
            this.toastrService.success('', 'Enviado correctamente');
            this.modalRef.close();
        }
    }

    public close() {
      if (this.modalRef) {
          this.modalRef.close();
      }
    }


}

export function emailValidator(control: FormControl): {[key: string]: any} {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
}