import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';
import { trigger,  state,  style, transition, animate } from '@angular/animations';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { DashboardState } from '../../../providers/state/dashboard.state';
import { IVEProvider } from "../../../providers/ive/ive";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('showInfo', [
      state('1' , style({ transform: 'rotate(180deg)' })),
      state('0', style({ transform: 'rotate(0deg)' })),
      transition('1 => 0', animate('400ms')),
      transition('0 => 1', animate('400ms'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  public showInfoContent:boolean = false;
  public settings: Settings;

    @ViewChild('loginModal') private loginModal;
    public modalRef: NgbModalRef;

    private username:string;
    private password:string;

    constructor(public appSettings:AppSettings, public dashboardState:DashboardState, private iveProvider:IVEProvider,
                public modalService: NgbModal, public toastrService: ToastrService,) {
      this.settings = this.appSettings.settings;
  }
  
  ngOnInit() {

  }


    login() {
        this.iveProvider.login(this.username, this.password).then( (assessorId) => {
            this.dashboardState.assessorId = assessorId;
            this.modalRef.close();
        }).catch( (error) => {
            this.toastrService.error('', 'Credenciales no válidas.');
        });
        this.password = '';
    }

    logout() {
        this.dashboardState.assessorId = '';
        this.password = '';
        this.toastrService.success('', 'Desconectado correctamente.');
    }

    openLoginModal() {
        this.modalRef = this.modalService.open(this.loginModal);

        this.modalRef.result.then((result) => {

        }, (reason) => {

        });
    }

  
}
