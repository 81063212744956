import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Response } from "../../interfaces/response.model";
import { Result } from "../../interfaces/result.model";
import { PostIVE } from "../../interfaces/postIVE.model";
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';

/*
  Generated class for the MapProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class IVEProvider {

  public settings: Settings;
  constructor(private httpClient:HttpClient, public appSettings:AppSettings) {
      this.settings = this.appSettings.settings;
  }

    login(username:string, password:string):Promise<string>{
        return new Promise((resolve, reject) => {
            let data = {
                username: username,
                password: password
            }
            this.httpClient.post(this.settings.server.loginEndpoint, data).subscribe((res) => {
                console.log(res);
                resolve(res['assessorId']);
            }, (error) => {
                reject();
            });
        });
    }


    postIVE(postIVE:PostIVE):Promise<Result>{
        return new Promise((resolve, reject) => {
            console.log('Datos a enviar: ', postIVE);
            this.httpClient.post<Result>(this.settings.server.calculatorEndpoint, postIVE).subscribe((res) => {
                console.log(res);
                resolve(res);
            }, (error) => {
                reject();
            });
        });
    }

}
